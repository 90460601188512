.App {
  text-align: center;
  display: flex;
  background-color: #eeeeee;
}

.Center {
  width: 80%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Sides {
  width: 10%;
}

.Titles {
  background-color: #002868;
  color: white;
  border-radius: .25rem;
  padding: 1rem;
  font-weight: bold;
}

p {
  font-weight: bold;
  font-size: 1.25rem;
}

.AboutCLCBody {
  text-align: left;
}

/* * {
  outline: 1px solid red;
} */

@media (max-width: 500px) {
  .App {
    text-align: center;
    display: flex;
  }

  p {
    font-weight: bold;
    font-size: .75rem;
  }

  .Center {
    width: 80%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
