.MinimumQualifications {
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.LiStyling {
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: center;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5rem;
  text-align: left;
}

.DownloadButtonBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
}

button {
  padding: 1rem;
  font-size: 1rem;
  border-radius: .5rem;
  font-weight: bold;
  background-color: white;
  color: #002868;
  outline: .25rem solid #002868;
  margin: 1rem;
  word-wrap: break-word;
  background: none;
  border: none;
  appearance: none;
}

button:hover{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

@media (max-width: 500px) {
  .textAligning {
    text-align: left;
    padding-left: 1rem;
  }
}
