.ContactForm {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
  padding: 1rem;
  font-size: 1.25rem;
}

input {
  height: 1.5rem;
  width: 30rem;
  margin: 1rem;
  padding: .5rem;
  border-radius: .25rem;

}

textarea {
  height: 5rem;
  width: 30rem;
  margin: 1rem;
  padding: .5rem;
  border-radius: .25rem;

}

label {
  font-weight: bold;
}

.SendButton {
  padding: 1rem;
  font-size: 1rem;
  border-radius: .5rem;
  font-weight: bold;
  background-color: #EEEEEE;
  color: #002868;
  outline: .25rem solid #002868;
  margin: 1rem;
  height: 2rem;
  width: 10rem;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

@media (max-width: 500px) {
  input {
    height: 1rem;
    width: 15rem;
    margin: 1rem;
    padding: .5rem;
    border-radius: .25rem;

  }

  textarea {
    height: 5rem;
    width: 15rem;
    margin: 1rem;
    padding: .5rem;
    border-radius: .25rem;

  }
}
