.CoLocations {
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

@media (max-width: 500px) {
  .CoLocations {
    display: flex;
  	flex-direction: column;
  	flex-wrap: wrap;
  	justify-content: flex-start;
  	align-items: flex-start;
  	align-content: center;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
