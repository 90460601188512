.Footer {
  background-color: #BF0A30;
  padding: 1rem;
  color: white;
  margin-top: auto;
  border-radius: .25rem;
}

.FooterWords {
  font-weight: bold;
  padding: .5rem;
}
