.HomePhoto {
  height: 100%;
  width: 100%;
  border-radius: .25rem;
}

.HomeParagraph {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MissionStatment {
  width: 80%;
  padding: 1rem;
  font-weight: bold;
}
