.Logo {
  height: 10rem;
  width: 10rem;
}

.Navigation {
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;
}

.NavigationButtons {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
  justify-content: space-evenly;
	align-items: stretch;
	align-content: stretch;
  width: 50%;
}

.ServiceButtonBox {
  display: flex;
  justify-content: space-evenly;
}

.ServiceButtons {
  padding: 1rem;
  font-size: 1rem;
  border-radius: .5rem;
  font-weight: bold;
  background-color: #EEEEEE;
  color: #002868;
  text-decoration: none;
  outline: .25rem solid #002868;
  margin: 1rem;
  height: 2rem;
  width: 10rem;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
}

.ServiceButtons:hover{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}


.NavBottomButtonBox {
  display: flex;
  justify-content: space-evenly;
}

.NavBottomButtons {
  background: none;
	color: inherit;
	border: none;
	padding: 1rem;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none
}

.NavBottomButtons:hover{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

@media (max-width: 500px) {
  .Logo {
    height: 6rem;
    width: 6rem;
    padding-top: 1rem;
  }

  .ServiceButtons {
    padding: .5rem;
    font-size: .60rem;
    border-radius: .25rem;
    font-weight: bold;
    background-color: #EEEEEE;
    color: #002868;
    text-decoration: none;
    outline: .25rem solid #002868;
    margin: 1rem;
    display: flex;
    flex-direction: column;
  	flex-wrap: wrap;
  	justify-content: center;
  	align-items: stretch;
  	align-content: stretch;
  }

  .NavBottomButtons {
    background: none;
  	color: inherit;
  	border: none;
  	padding: 1rem;
  	font: inherit;
  	cursor: pointer;
  	outline: inherit;
    font-size: .75rem;
    font-weight: bold;
    text-decoration: none
  }
}
